import { type QueryObserverOptions, useQuery } from "@tanstack/react-query"
import { SEARCH_PRODUCT } from "@/app/(navigation-layout)/federated/queries"
import {
  type SearchProductsQuery,
  type SearchProductsQueryVariables,
} from "@/federatedGql/graphql"
import { type GenericExtractor } from "@/types/type"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TSearchProductResult = GenericExtractor<
  SearchProductsQuery["searchProducts"]
>

type TUseSearchProduct = {
  filter: SearchProductsQueryVariables
  options?: QueryObserverOptions<TSearchProductResult>
  enabledSearch?: boolean
}

const useSearchProduct = ({
  filter,
  options,
  enabledSearch,
}: TUseSearchProduct) => {
  const query = useQuery({
    queryKey: ["searchProduct", filter],
    enabled: enabledSearch,
    queryFn: async () => {
      const res = await federatedGqlClient.request(SEARCH_PRODUCT, filter)

      if (res.searchProducts.__typename === "GenericError") {
        throw new Error(res.searchProducts.message)
      }

      return res.searchProducts
    },
    refetchOnWindowFocus: false,
    ...options,
  })
  return query
}

export default useSearchProduct
