export const isNumber = (value: string | number): boolean => {
  const number = Number(value)

  return Boolean(number)
}

export const generateRangeNumber = (start: number, end: number) => {
  const arr: Array<number> = []
  let currentValue = start

  while (currentValue <= end) {
    arr.push(currentValue++)
  }

  return arr
}

export const hasDecimal = (number: number) => !Number.isInteger(number)

export const roundUpDigits = (number: number) => {
  const integerPart = Math.floor(number)
  const decimalPart = Math.ceil((number - integerPart) * 100) / 100
  return integerPart + decimalPart
}
