import { graphql } from "@/federatedGql"

export const ADD_PERSONA_SHIPPING_ADDRESS = graphql(`
  mutation addPersonaShippingAddress(
    $personaId: String!
    $input: AddressesTypeInput!
  ) {
    addPersonaShippingAddress(input: $input, personaId: $personaId) {
      ... on AddressesType {
        __typename
        id
      }
      ... on GenericError {
        __typename
        reqId
        code
        message
      }
    }
  }
`)

export const UPDATE_PERSONA_SHIPPING_ADDRESS = graphql(`
  mutation updatePersonaShippingAddress(
    $id: String!
    $input: AddressesTypeInput!
  ) {
    updatePersonaShippingAddress(id: $id, input: $input) {
      ... on AddressesType {
        __typename
        id
      }
      ... on GenericError {
        __typename
        reqId
        code
        message
      }
    }
  }
`)

export const DELETE_PERSONA_SHIPPING_ADDRESS = graphql(`
  mutation deletePersonaShippingAddress($id: String!) {
    deletePersonaShippingAddress(id: $id) {
      ... on DeletePersonaShippingAddressResult {
        __typename
        status
      }
      ... on GenericError {
        __typename
        reqId
        code
        message
      }
    }
  }
`)
