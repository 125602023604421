"use client"

import Link from "next/link"
import { Alert } from "shared-ui"

import { ContractEsignStatus } from "@/federatedGql/graphql"
import { useAlertTTEStore } from "@/store/useAlertTTEStore"

type TAlertComponent = {
  BSrEStatus?: ContractEsignStatus
}

const AlertComponent = ({ BSrEStatus }: TAlertComponent) => {
  const { isShow, setIsShow } = useAlertTTEStore()

  if (
    (BSrEStatus === ContractEsignStatus.BelumTerdaftar ||
      BSrEStatus === ContractEsignStatus.Dicabut ||
      BSrEStatus === ContractEsignStatus.Kedaluwarsa) &&
    isShow
  )
    return (
      <Alert
        variant="warning"
        title="Aktivasi Tanda Tangan Elektronik"
        description={
          <p>
            Pada katalog elektronik v.6, PPK wajib memiliki akun Tanda Tangan
            Elektronik (TTE) aktif yang diterbitkan oleh Balai Sertifikasi
            Elektronik (BSrE) untuk menandatangani surat pesanan dan BAST
            digital. Silakan daftarkan akun BSrE dengan menghubungi verifikator
            TTE BSrE di instansi tempat Anda bekerja. Lihat panduan lebih lanjut
            di{" "}
            <Link
              target="_blank"
              href="https://bsre.bssn.go.id/repository/juknis"
              className="text-secondary500 underline"
            >
              Petunjuk Teknis Aplikasi Manajemen Sertifikat
            </Link>{" "}
            atau hubungi{" "}
            <Link
              target="_blank"
              href="https://t.me/infobsre"
              className="text-secondary500 underline"
            >
              Customer Service BSRE.
            </Link>
          </p>
        }
        onClose={() => setIsShow(false)}
      />
    )

  return null
}

export default AlertComponent
