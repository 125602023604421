export const productTypeLabel = (name: string) => {
  switch (name) {
    case "SERVICE":
      return "Jasa"
    case "DIGITAL":
      return "Digital"
    case "PHYSICAL":
      return "Barang"
    default:
      return ""
  }
}
