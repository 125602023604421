import { type THomepageSRP } from "@/commons/types/product"
import {
  ProductType,
  SearchProductStrategy,
  StockUnitType,
} from "@/federatedGql/graphql"
import capitalize from "@/utils/capitalize"
import { FLAG_TKDN_UMKK_FILTER } from "./flags"

export const DEFAULT_REGION_CODE = "31.71"

export const HOMEPAGE_SRP: THomepageSRP[] = [
  {
    strategy: SearchProductStrategy.Tkdn,
    title: "Produk Bersertifikat TKDN",
    showOnlyOnLogin: false,
    srpRoute: FLAG_TKDN_UMKK_FILTER
      ? `/search?page=1&source=homepage.${SearchProductStrategy.Tkdn.toLowerCase()}&tkdnBmp=1&labels=%252CPDN`
      : `/search?page=1&source=homepage.${SearchProductStrategy.Tkdn.toLowerCase()}`,
  },
  {
    strategy: SearchProductStrategy.TkdnUmkk,
    title: "Produk dalam Negeri dari UMKK",
    showOnlyOnLogin: false,
    srpRoute: FLAG_TKDN_UMKK_FILTER
      ? `/search?page=1&source=homepage.${SearchProductStrategy.TkdnUmkk.toLowerCase()}&sellerTypes=%252CUMKK&labels=%252CPDN`
      : `/search?page=1&source=homepage.${SearchProductStrategy.TkdnUmkk.toLowerCase()}`,
  },
  {
    strategy: SearchProductStrategy.Local,
    title: "Produk dari Daerahmu",
    showOnlyOnLogin: true,
    srpRoute: `/search?page=1&source=homepage.${SearchProductStrategy.Local.toLowerCase()}`,
  },
]

export const PRODUCT_TYPE: Record<string, string> = {
  [ProductType.Physical]: "Barang",
  [ProductType.Digital]: "Digital",
  [ProductType.Service]: "Jasa",
}

export const renderStockUnitTypeLabel = (unit: StockUnitType | "") => {
  const specialUnit = [
    StockUnitType.M2,
    StockUnitType.M3,
    StockUnitType.SatuanUkur,
  ]
  if (!specialUnit.includes(unit)) {
    return capitalize(unit || "")
  }

  switch (unit) {
    case StockUnitType.SatuanUkur:
      return "Satuan Ukur"
    case StockUnitType.M2:
      return "M\u00B2"
    default:
      return "M\u00B3"
  }
}
