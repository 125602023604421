import { cx } from "class-variance-authority"
import { ChevronLeft, ChevronRight } from "react-feather"
import { type ArrowProps } from "react-multi-carousel/lib/types"

type TArrowCustomProps = {
  arrow: "left" | "right"
}

const ArrowCustom = ({ onClick, arrow }: ArrowProps & TArrowCustomProps) => {
  const isArrowLeft = arrow === "left"

  return (
    <button
      className={cx(
        "absolute z-10 flex h-12 w-12 scale-75 items-center justify-center rounded-full bg-primary25 text-tertiary500 opacity-0 shadow-4 transition-all ease-in-out group-hover:scale-100 group-hover:opacity-100 ",
        isArrowLeft ? "-left-5" : "-right-5"
      )}
      onClick={onClick}
    >
      {isArrowLeft ? <ChevronLeft size={32} /> : <ChevronRight size={32} />}
    </button>
  )
}

export default ArrowCustom
