import { createMutation } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import {
  type UpdatePersonaShippingAddressMutationVariables,
  type UpdatePersonaShippingAddressMutation,
  type GenericError,
} from "@/federatedGql/graphql"
import { UPDATE_PERSONA_SHIPPING_ADDRESS } from "@/hooks/address/federated/mutations"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TUseUpdatePersonaShippingAddress = GenericExtractor<
  UpdatePersonaShippingAddressMutation["updatePersonaShippingAddress"]
>

const useUpdatePersonaShippingAddress = createMutation<
  TUseUpdatePersonaShippingAddress,
  UpdatePersonaShippingAddressMutationVariables,
  GenericError
>({
  mutationKey: ["updatePersonaShippingAddress"],
  mutationFn: async ({ id, input }) => {
    const result = await federatedGqlClient.request(
      UPDATE_PERSONA_SHIPPING_ADDRESS,
      {
        id,
        input,
      }
    )

    if (result.updatePersonaShippingAddress.__typename === "GenericError") {
      throw new Error(result.updatePersonaShippingAddress.message)
    }

    return result.updatePersonaShippingAddress
  },
})

export default useUpdatePersonaShippingAddress
