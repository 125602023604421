import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import {
  type GetPersonaShippingAddressQuery,
  type GetPersonaShippingAddressQueryVariables,
} from "@/federatedGql/graphql"
import { GET_PERSONA_SHIPPING_ADDRESS } from "@/hooks/address/federated/queries"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TUseGetPersonaShippingAddressResponse = GenericExtractor<
  GetPersonaShippingAddressQuery["getPersonaNonpenyediaByID"]
>

const useGetPersonaShippingAddress = createQuery<
  TUseGetPersonaShippingAddressResponse,
  GetPersonaShippingAddressQueryVariables
>({
  primaryKey: "getPersonaShippingAddress",
  queryFn: async ({ queryKey: [, variables] }) => {
    const res = await federatedGqlClient.request(
      GET_PERSONA_SHIPPING_ADDRESS,
      variables
    )
    if (res.getPersonaNonpenyediaByID?.__typename === "GenericError") {
      throw Error(res.getPersonaNonpenyediaByID.message || "")
    }
    return res.getPersonaNonpenyediaByID
  },
})

export default useGetPersonaShippingAddress
