import { type QueryObserverOptions, useQuery } from "@tanstack/react-query"

import { type GetTraceParentRegionQuery } from "@/federatedGql/graphql"
import { GET_TRACE_PARENT_REGION } from "@/hooks/address/federated/queries"
import { federatedGqlClient } from "@/utils/graphqlClient"

type TUseGetTraceParentRegion = {
  areaCodes: string[]
  options?: QueryObserverOptions<
    NonNullable<GetTraceParentRegionQuery["getTraceParentRegion"]>["items"]
  >
}

const useGetTraceParentRegion = ({
  areaCodes,
  options,
}: TUseGetTraceParentRegion) => {
  const query = useQuery({
    queryFn: async () => {
      const res = await federatedGqlClient.request(GET_TRACE_PARENT_REGION, {
        areaCodes,
      })
      if (!res.getTraceParentRegion) {
        throw new Error("error") //To do apply error msg from BE
      }
      return res.getTraceParentRegion.items
    },
    queryKey: ["getTraceParentRegion", areaCodes],
    refetchOnWindowFocus: false,
    enabled: Boolean(areaCodes.length),
    ...options,
  })
  return query
}

export default useGetTraceParentRegion
