"use client"

import { useFormLevelAddAddressStore } from "gtp-ui"
import {
  type TTempAddress,
  type TFormModalAdd,
} from "gtp-ui/src/address/modal-add-address/type"
import {
  type ChangeEvent,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react"
import { useSnackbar } from "shared-ui"
import { useDebounce } from "shared-utils"
import { getUserInfo } from "shared-utils/authentication"

import useAddPersonaShippingAddress from "./useAddPersonaShippingAddress"
import useDeletePersonaShippingAddress from "./useDeletePersonaShippingAddress"
import useGetPersonaShippingAddress from "./useGetPersonaShippingAddress"
import useGetTraceParentRegion from "./useGetTraceParentRegion"
import updatePersonaShippingAddress from "./useUpdatePersonaShippingAddress"
import { type AddressesTypeInput } from "@/federatedGql/graphql"
import convertTempAddressIntoAddress from "@/hooks/address/convertTempAddressIntoAddress"

type TUseAddressSettingsProps = {
  isPreventUpdateToGraphql?: boolean
}

export default function useAddressSettings({
  isPreventUpdateToGraphql: isPreventUpdateMainAddressToGraphql,
}: TUseAddressSettingsProps = {}) {
  const session = getUserInfo()
  const { enqueueSnackbar } = useSnackbar()

  const [formDefaultData, setFormDefaultData] = useState<TFormModalAdd>()
  const [allAddress, setAllAddress] = useState<TTempAddress[]>([])
  const [addressList, setAddressList] = useState<TTempAddress[]>([])
  const [doneFetching, setDoneFetching] = useState(false)
  const [addressIdToDelete, setAddressIdToDelete] = useState<string>("")
  const [mainAddressId, setMainAddressId] = useState<string>("")

  const {
    data: shippingAddress,
    isLoading,
    isFetching,
    refetch,
    isRefetching,
  } = useGetPersonaShippingAddress({
    variables: {
      id: session?.user?.personaId || "",
    },
    enabled: Boolean(session?.user?.personaId),
    onError: (e) => {
      enqueueSnackbar({
        message:
          e?.message || "Gagal mendapat data. Mohon ulangi beberapa saat lagi.",
        type: "error",
      })
    },
    onSuccess: (resp) => {
      if (resp.personaShippingAddress === null) {
        setDoneFetching(true)
      }
    },
  })

  const areaCodes =
    shippingAddress?.personaShippingAddress?.map(
      (address) => address?.villageAreaCode
    ) ?? []

  const { refetch: refetchGetTraceParentRegion } = useGetTraceParentRegion({
    areaCodes,
  })

  const handleError = () => {
    enqueueSnackbar({
      message: `Gagal ${
        !!addressIdToDelete ? "menghapus" : "menyimpan"
      } alamat. Mohon ulangi beberapa saat lagi.`,
      type: "error",
    })
  }

  const fetchData = useCallback(async () => {
    if (!areaCodes.length) return
    const { data: parentRegions } = await refetchGetTraceParentRegion()

    const tmpAddress = shippingAddress?.personaShippingAddress
    if (tmpAddress) {
      const buyerAddresses = tmpAddress
        ?.map((address, idx) => {
          const phone = address?.phoneNumber ?? ""

          return {
            id: address.id || "",
            snapshotId: address.snapshotId ?? "",
            label: address?.label || "-",
            receiverName: address?.receiverName || "",
            phoneNumber: phone.slice(phone.indexOf("62") + 2) ?? "",
            formattedAddress: address?.fullAddress || "",
            province: {
              id: parentRegions?.[idx]?.provinceId || "",
              name: parentRegions?.[idx]?.provinceName || "",
              areaCode: parentRegions?.[idx]?.provinceAreaCode || "",
            },
            city: {
              id: parentRegions?.[idx]?.cityId || "",
              name: parentRegions?.[idx]?.cityName || "",
              areaCode: parentRegions?.[idx]?.cityAreaCode || "",
            },
            district: {
              id: parentRegions?.[idx]?.districtId || "",
              name: parentRegions?.[idx]?.districtName || "",
              areaCode: parentRegions?.[idx]?.districtAreaCode || "",
            },
            village: {
              id: parentRegions?.[idx]?.villageId || "",
              name: parentRegions?.[idx]?.villageName || "",
              areaCode: parentRegions?.[idx]?.villageAreaCode || "",
            },
            postalCode: parentRegions?.[idx]?.villagePostalCode || "",
            latitude: address?.latitude || 0,
            longitude: address?.longitude || 0,
            isMainAddress: address?.isMainAddress || false,
            notes: address?.notes || "",
          }
        })
        .sort((a, b) => (a.isMainAddress ? -1 : b.isMainAddress ? 1 : 0))
      setAddressList(buyerAddresses)
      setAllAddress(buyerAddresses)
    }
    setDoneFetching(true)
  }, [shippingAddress])

  useEffect(() => {
    fetchData()
  }, [shippingAddress, fetchData])

  /* Edit Logic STARTS */
  const setFormLevel = useFormLevelAddAddressStore((state) => state.setForm)
  function editAddressHandler(idx: number) {
    const tmpSelected = addressList[idx]
    if (tmpSelected) {
      setFormDefaultData({
        id: tmpSelected.id,
        detailAddress: convertTempAddressIntoAddress(tmpSelected),
      })
      setFormLevel("detailAddress")
    }
  }
  /* Edit Logic ENDS */

  /* Search Logic STARTS */
  const [addressSearchValue, setAddressSearchValue] = useState("")
  const debounceQueryFilter = useDebounce(addressSearchValue, 500)
  const searchInputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setAddressSearchValue(e.target.value)
  }

  useEffect(() => {
    if (!!debounceQueryFilter) {
      const res = allAddress.filter((address) => {
        const fullAddress = address.formattedAddress.toLowerCase()
        const name = address.receiverName.toLowerCase()
        const searchValue = debounceQueryFilter.toLowerCase()

        if (fullAddress.includes(searchValue) || name.includes(searchValue)) {
          return true
        }
        return false
      })
      setAddressList(res)
    } else {
      setAddressList(allAddress)
    }
  }, [debounceQueryFilter, allAddress])

  /* Search Logic ENDS */

  /* Handle After Success STARTS*/
  const handleAfterSuccess = () => {
    setFormLevel("findAddress")
    setFormDefaultData(undefined)
  }
  /* Handle After Success ENDS*/

  /* Delete Logic STARTS */
  const { mutate: deleteAddress, isLoading: loadingDelete } =
    useDeletePersonaShippingAddress()

  const deleteAddressHandler = (addressId: string) => {
    setAddressIdToDelete(addressId)
  }
  const confirmDeleteHandler = (callbackFn?: () => void) => {
    deleteAddress(
      {
        id: addressIdToDelete,
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            message: "Anda berhasil menghapus alamat.",
            type: "success",
          })
          refetch()
          callbackFn && callbackFn()
          handleAfterSuccess()
        },
        onError: handleError,
      }
    )
  }
  /* Delete Logic ENDS */

  const defaultAddress = useMemo<TTempAddress | undefined>(() => {
    if (!allAddress) return undefined
    return allAddress.filter((address) => address.isMainAddress)[0]
  }, [allAddress])

  const { mutate: addAddress, isLoading: loadingAdd } =
    useAddPersonaShippingAddress()

  const { mutate: updateAddress, isLoading: loadingEdit } =
    updatePersonaShippingAddress()

  const handleSubmitAddress = (
    addressInput: TFormModalAdd,
    additionalSuccessCallback?: (addressId: string) => void
  ) => {
    const detail = addressInput.detailAddress
    const inputData = {
      label: detail.label,
      fullAddress: detail.address,
      receiverName: detail.name,
      phoneNumber: `62${detail.phone}`,
      latitude: detail.googleMaps.latLng?.lat,
      longitude: detail.googleMaps.latLng?.lng,
      notes: detail.notes,
      postalCode: detail.postalCode.name,
      villageAreaCode: detail.village.areaCode,
      isMainAddress: detail.isPrimaryAddress,
    }

    if (!!formDefaultData) {
      // UPDATE address
      updateAddress(
        {
          id: formDefaultData.id,
          input: inputData as AddressesTypeInput,
        },
        {
          onSuccess: (updateRes) => {
            const addressId = updateRes.id || ""
            enqueueSnackbar({
              message: "Anda berhasil mengubah alamat.",
              type: "success",
            })
            refetch()
            additionalSuccessCallback && additionalSuccessCallback(addressId)
            handleAfterSuccess()
          },
          onError: handleError,
        }
      )
      return
    }
    // ADD address
    addAddress(
      {
        personaId: session?.user?.personaId || "",
        input: inputData as AddressesTypeInput,
      },
      {
        onSuccess: (addRes) => {
          const addressId = addRes.id || ""
          enqueueSnackbar({
            message: "Anda berhasil menambah alamat.",
            type: "success",
          })
          refetch()
          additionalSuccessCallback && additionalSuccessCallback(addressId)
          handleAfterSuccess()
        },
        onError: handleError,
      }
    )
  }

  const setMainAddressHandler = (addressId: string) => {
    setMainAddressId(addressId)
  }
  const confirmSetMainAddressHandler = ({
    selectedAddress,
    callbackFn,
  }: {
    selectedAddress?: TTempAddress
    callbackFn?: () => void
  }) => {
    const id = selectedAddress?.id ?? mainAddressId
    if (!id) return

    const addressInput = {
      fullAddress: selectedAddress?.formattedAddress ?? "",
      isMainAddress: true,
      label: selectedAddress?.label ?? "",
      latitude: selectedAddress?.latitude ?? 0,
      longitude: selectedAddress?.longitude ?? 0,
      phoneNumber: selectedAddress?.phoneNumber ?? "",
      postalCode: selectedAddress?.postalCode ?? "",
      receiverName: selectedAddress?.receiverName ?? "",
      villageAreaCode: selectedAddress?.village.areaCode ?? "",
    }

    if (isPreventUpdateMainAddressToGraphql) {
      refetch()
      handleAfterSuccess()
      callbackFn?.()
      return
    }

    updateAddress(
      {
        id,
        input: addressInput,
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            message: "Anda berhasil mengubah alamat utama.",
            type: "success",
          })
          refetch()
          handleAfterSuccess()
          if (callbackFn) {
            callbackFn()
          }
        },
      }
    )
  }

  return {
    formDefaultData,
    addressList,
    defaultAddress,
    loading: isLoading || isFetching || isRefetching,
    refetch,
    editAddressHandler,
    handleAfterSuccess,
    doneFetching,

    addressSearchValue,
    searchInputChangeHandler,

    deleteAddressHandler,
    confirmDeleteHandler,
    loadingDelete,

    loadingAdd,
    loadingEdit,
    handleSubmitAddress,

    setMainAddressHandler,
    confirmSetMainAddressHandler,
  }
}
