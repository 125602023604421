export const formatSold = (value: number) => {
  const suffixes = ["", "rb", "jt", "miliar", "triliun"]
  const power = Math.floor(Math.log10(value) / 3)

  const scaledNumber = value / Math.pow(10, power * 3)
  const roundedNumber = Math.round(scaledNumber * 10) / 10

  if (value === 0) {
    return `Terjual 0`
  } else if (value < 100) {
    return `Terjual ${roundedNumber} ${suffixes[power]}`
  } else {
    return `Terjual ${roundedNumber}${suffixes[power]}+`.replace(".", ",")
  }
}
