import { type TTempAddress } from "gtp-ui/src/address/modal-add-address/type"
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

type TSelectedBuyerAddressStore = {
  selectedBuyerAddress?: TTempAddress | undefined
  updateSelectedBuyerAddress: (data: TTempAddress | undefined) => void
  resetSelectedBuyerAddress: () => void
}

export const useSelectedBuyerAddressStore =
  create<TSelectedBuyerAddressStore>()(
    devtools(
      persist(
        (set) => ({
          selectedBuyerAddress: undefined,
          updateSelectedBuyerAddress: (data: TTempAddress | undefined) =>
            set(() => ({ selectedBuyerAddress: data })),
          resetSelectedBuyerAddress: () =>
            set(() => ({ selectedBuyerAddress: undefined })),
        }),
        {
          name: "selected-buyer-address-storage",
        }
      )
    )
  )
