import { createMutation } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import {
  type AddPersonaShippingAddressMutationVariables,
  type AddPersonaShippingAddressMutation,
  type GenericError,
} from "@/federatedGql/graphql"
import { ADD_PERSONA_SHIPPING_ADDRESS } from "@/hooks/address/federated/mutations"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TUseAddPersonaShippingAddress = GenericExtractor<
  AddPersonaShippingAddressMutation["addPersonaShippingAddress"]
>

const useAddPersonaShippingAddress = createMutation<
  TUseAddPersonaShippingAddress,
  AddPersonaShippingAddressMutationVariables,
  GenericError
>({
  mutationKey: ["addPersonaShippingAddress"],
  mutationFn: async ({ personaId, input }) => {
    const result = await federatedGqlClient.request(
      ADD_PERSONA_SHIPPING_ADDRESS,
      {
        personaId,
        input,
      }
    )

    if (result.addPersonaShippingAddress.__typename === "GenericError") {
      throw new Error(result.addPersonaShippingAddress.message)
    }

    return result.addPersonaShippingAddress
  },
})

export default useAddPersonaShippingAddress
