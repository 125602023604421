import {
  type TTempAddress,
  type TAddress,
} from "gtp-ui/src/address/modal-add-address/type"
import { formatProvince } from "shared-utils"

import { mapRegion } from "./mapRegion"
import capitalize from "@/utils/capitalize"

export default function convertTempAddressIntoAddress(
  tempAddress: TTempAddress
): TAddress {
  const province = mapRegion(tempAddress.province)

  return {
    label: tempAddress.label,
    name: tempAddress.receiverName,
    phone: tempAddress.phoneNumber,
    province: {
      id: province.id,
      name: formatProvince(province.name),
      areaCode: province.areaCode,
    },
    city: mapRegion(tempAddress.city),
    district: mapRegion(tempAddress.district),
    village: mapRegion(tempAddress.village),
    postalCode: {
      areaCode: "",
      id: "",
      name: tempAddress.postalCode,
    },
    googleMaps: {
      ...(!!tempAddress.latitude &&
        !!tempAddress.longitude && {
          latLng: {
            lat: tempAddress.latitude,
            lng: tempAddress.longitude,
          },
        }),
      addressName: tempAddress.formattedAddress,
      placeId: tempAddress.id,
      addressSecondary: `Kecamatan ${capitalize(
        tempAddress.district.name
      )}, ${capitalize(tempAddress.city.name)}, ${capitalize(
        tempAddress.province.name
      )}`,
      districtAreaCode: tempAddress.district.areaCode,
    },
    address: tempAddress.formattedAddress,
    notes: tempAddress.notes || undefined,
    isPrimaryAddress: tempAddress.isMainAddress,
  }
}
