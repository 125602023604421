import { capitalize } from ".."

const formatProvince = (province: string) => {
  const SELECTED_PROVINCE = "dki jakarta"

  switch (province.toLowerCase()) {
    case SELECTED_PROVINCE:
      const newValue = province.split(" ")
      return `${newValue[0]?.toUpperCase()} ${capitalize(newValue[1] ?? "")}`

    default:
      return capitalize(province)
  }
}

export default formatProvince