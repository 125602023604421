"use client"
import { LazyLoadComponent } from "react-lazy-load-image-component"

import HomepageSRPList from "./HomepageSRPList"
import { HOMEPAGE_SRP } from "@/commons/constants/product"
import type { FeatureFlag } from "@/utils/getFlag"

type TCarouselProps = {
  readonly featureFlags?: FeatureFlag
}
const Carousel = ({ featureFlags }: TCarouselProps) => {
  return (
    <>
      {HOMEPAGE_SRP.map((srp, index) => {
        if (index > 1) {
          return (
            <LazyLoadComponent key={`${srp.title}-${index}`}>
              <HomepageSRPList {...srp} featureFlags={featureFlags} />
            </LazyLoadComponent>
          )
        }
        return (
          <HomepageSRPList
            {...srp}
            key={`${srp.title}-${index}`}
            featureFlags={featureFlags}
          />
        )
      })}
    </>
  )
}

export default Carousel
