type PriceThreshold = {
  threshold: number
  unit: string
  divisor: number
  formatter?: (price: number) => string
}

const PRICE_THRESHOLDS: PriceThreshold[] = [
  {
    threshold: 100_000,
    unit: "",
    divisor: 1,
    formatter: (p) => p.toLocaleString("id-ID"),
  },
  { threshold: 1_000_000, unit: "rb", divisor: 1_000 },
  { threshold: 10_000_000, unit: "jt", divisor: 1_000_000 },
  { threshold: 100_000_000, unit: "jt", divisor: 1_000_000 },
  { threshold: 1_000_000_000, unit: "jt", divisor: 1_000_000 },
  { threshold: 10_000_000_000, unit: "M", divisor: 1_000_000_000 },
  { threshold: 100_000_000_000, unit: "M", divisor: 1_000_000_000 },
  { threshold: 1_000_000_000_000, unit: "M", divisor: 1_000_000_000 },
  { threshold: 10_000_000_000_000, unit: "T", divisor: 1_000_000_000_000 },
  { threshold: 100_000_000_000_000, unit: "T", divisor: 1_000_000_000_000 },
  {
    threshold: Number.POSITIVE_INFINITY,
    unit: "T",
    divisor: 1_000_000_000_000,
  },
]

const initFormatPrice = (price: number): string => {
  const matchedThreshold = PRICE_THRESHOLDS.find((t) => price < t.threshold)!

  if (matchedThreshold.formatter) {
    return matchedThreshold.formatter(price)
  }

  return `${Math.floor(price / matchedThreshold.divisor)} ${
    matchedThreshold.unit
  }`
}

export const customFormatRegionPrice = (
  minPrice: number,
  maxPrice: number
): string => {
  const formattedMin = initFormatPrice(minPrice)
  const formattedMax = initFormatPrice(maxPrice)

  const minParts = formattedMin.split(" ")
  const maxParts = formattedMax.split(" ")

  return minParts[1] === maxParts[1]
    ? `Rp${minParts[0]} - ${formattedMax}`
    : `Rp${formattedMin} - ${formattedMax}`
}
