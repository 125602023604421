import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

type TAlertTTEStoreProps = {
  isShow: boolean
  setIsShow: (show: boolean) => void
}

export const useAlertTTEStore = create<TAlertTTEStoreProps>()(
  devtools(
    persist(
      (set) => ({
        isShow: true,
        setIsShow: (show) =>
          set(() => ({
            isShow: show,
          })),
      }),
      {
        name: "alert-tte-storage",
      }
    )
  )
)
