"use client"

const ProductEmptyState = () => {
  return (
    <section className="my-4 w-full rounded-3 border border-tertiary50 bg-tertiary25">
      <div className="flex h-[250px] flex-col items-center justify-center">
        <div className="mb-4 text-lg font-bold text-tertiary500">
          Maaf, produk belum tersedia
        </div>
        <div className="text-base font-normal text-tertiary300">
          Produk pada koleksi ini belum tersedia, silakan telusuri koleksi lain.
        </div>
      </div>
    </section>
  )
}

export default ProductEmptyState
