import { cx } from "class-variance-authority"
import { ChevronLeft, ChevronRight } from "react-feather"

export const ButtonRight = (
  onClick: React.MouseEventHandler<HTMLElement>,
  disabled: boolean
) => (
  <button
    className={cx(
      "absolute -bottom-[70px] right-1 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary25 shadow-4",
      disabled && "hidden"
    )}
    onClick={onClick}
    disabled={disabled}
  >
    <ChevronRight size={16} />
  </button>
)

export const ButtonLeft = (
  onClick: React.MouseEventHandler<HTMLElement>,
  disabled: boolean
) => (
  <button
    className={cx(
      "absolute -bottom-[70px] left-1 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary25 shadow-4",
      disabled && "hidden"
    )}
    onClick={onClick}
    disabled={disabled}
  >
    <ChevronLeft size={16} />
  </button>
)
