import { createMutation } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import {
  type DeletePersonaShippingAddressMutationVariables,
  type DeletePersonaShippingAddressMutation,
  type GenericError,
} from "@/federatedGql/graphql"
import { DELETE_PERSONA_SHIPPING_ADDRESS } from "@/hooks/address/federated/mutations"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TUseDeletePersonaShippingAddress = GenericExtractor<
  DeletePersonaShippingAddressMutation["deletePersonaShippingAddress"]
>

const useDeletePersonaShippingAddress = createMutation<
  TUseDeletePersonaShippingAddress,
  DeletePersonaShippingAddressMutationVariables,
  GenericError
>({
  mutationKey: ["deletePersonaShippingAddress"],
  mutationFn: async ({ id }) => {
    const result = await federatedGqlClient.request(
      DELETE_PERSONA_SHIPPING_ADDRESS,
      {
        id,
      }
    )

    if (result.deletePersonaShippingAddress.__typename === "GenericError") {
      throw new Error(result.deletePersonaShippingAddress.message)
    }

    return result.deletePersonaShippingAddress
  },
})

export default useDeletePersonaShippingAddress
