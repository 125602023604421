"use client"
import Image from "next/image"
import { useState } from "react"
import ImageGallery, { type ReactImageGalleryItem } from "react-image-gallery"

import { ButtonRight, ButtonLeft } from "./button"
import { CDN_URL } from "@/config/client"

import "react-image-gallery/styles/css/image-gallery.css"
import "./style.css"

type TBannerSliderProps = {
  data: string[]
}

export const BannerSlider = ({ data }: TBannerSliderProps) => {
  const [isImageError, setIsImageError] = useState<boolean>(false)
  const fallbackImgUrl = `${CDN_URL}/buyer-banner/banner-${1}.png`

  const renderImage = (item: ReactImageGalleryItem) => {
    return (
      <>
        {item?.original && (
          <Image
            priority
            src={isImageError ? fallbackImgUrl : item?.original}
            width={1205}
            height={287}
            alt="test"
            className="w-full object-cover xl:h-[287px]"
            onError={() => setIsImageError(true)}
          />
        )}
      </>
    )
  }

  const isValidImage = (url: string): boolean => {
    return (
      url.indexOf("govmart/buyer-banner") > -1 ||
      url.match(/\.(jpeg|jpg|gif|png)$/) != null
    )
  }

  const items = data.map((dt: string) => {
    const temp: ReactImageGalleryItem = {
      original: dt,
    }

    if (isValidImage(dt)) {
      temp.renderItem = renderImage
      temp.thumbnail = dt
    }

    return temp
  })

  return (
    <div className="mb-6">
      <ImageGallery
        items={items}
        lazyLoad={true}
        showBullets={true}
        infinite={true}
        showPlayButton={false}
        additionalClass="banner-image-gallery"
        renderRightNav={ButtonRight}
        renderLeftNav={ButtonLeft}
        showFullscreenButton={false}
        showThumbnails={false}
        autoPlay={true}
        slideDuration={1000}
        showNav={true}
        slideInterval={5000}
      />
    </div>
  )
}

export default BannerSlider
